import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { AxiosError } from 'axios';
import { Alert } from 'flowbite-react';
import { EnrolledCourse } from '../../types';
import { imageUrls } from '../../utils/constants';
import { LoadingSpinner, Pagination } from '../../components';
import { StudentCourseComponent } from './components';

const StudentPersonalCourses = () => {
  const [enrolledCourses, setEnrolledCourses] = useState<EnrolledCourse[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [coursesPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const getEnrolledCourses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/enrollment/courses`, {
        params: {
          page: currentPage,
          limit: coursesPerPage,
        },
      });
      setEnrolledCourses(res.data);
      setTotalPages(Math.ceil(res.data.totalDocuments / coursesPerPage));
      setLoading(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        setLoading(false);
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getEnrolledCourses();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="pt-3 h-full">
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : enrolledCourses && enrolledCourses.length > 0 ? (
        <div className="flex flex-col h-full light-bg-grey">
          <div className="grid lg:grid-cols-12 gap-4">
            {enrolledCourses.map((course: EnrolledCourse) => (
              <StudentCourseComponent course={course} key={course.id} />
            ))}
          </div>
          <div className="mx-auto mt-auto">
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center content-center h-100">
          <div>
            <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-3 cursor-pointer h-48" />
            <p className="text-center font-lato text-lg font-bold ">Sorry, there is no content here at the moment.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentPersonalCourses;
