import React, { useState, useRef, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { generateSignedUrl, uploadToGCS, determineMediaType, extractCloudUrl } from './utils';
import { SignedUrlData, UploadComponentProps, UploadResponse } from './types';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileMetadata,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

export const UploadComponent = ({
  acceptedFileTypes = ['video/*', 'image/*', 'application/*'],
  mediaType,
  onError = () => {},
  onSuccess = (_uploadResponse: UploadResponse) => {},
  onAbort = () => {},
  onProgress = () => {},
  startUpload,
  filename,
  imageAspectRatio,
}: Readonly<UploadComponentProps>) => {
  const pondRef = useRef<FilePond>(null);
  const [media, setMedia] = useState<any[]>([]);
  const [signedUrlData, setSignedUrlData] = useState<SignedUrlData | undefined>();

  const handleUploadComplete = (filePondLoad: Function) => {
    if (!signedUrlData) return;
    filePondLoad();
    onSuccess({
      url: extractCloudUrl(signedUrlData.url),
      type: determineMediaType(media[0]['type']).toLowerCase(),
      size: media[0]['size'],
      filename,
    });
  };

  const handleUploadAbort = (filePondAbort: Function) => {
    filePondAbort();
    onAbort();
  };

  const handleUploadProgress = (filePondProgress: Function) => {
    filePondProgress();
    onProgress();
  };

  const handleUploadError = (err: string, filePondError: Function) => {
    filePondError();
    onError(err);
  };

  useEffect(() => {
    const processFiles = async () => {
      if (startUpload && pondRef.current) {
        const filesToUpload = pondRef.current.getFiles();
        if (filesToUpload.length > 0) {
          onError('');
          try {
            const response = await generateSignedUrl(media);
            setSignedUrlData(response);
          } catch (error) {
            onError(error);
          }

          await pondRef.current.processFiles();
        } else {
          onError('Please Select A Media File To Upload');
        }
      }
    };
    processFiles();
  }, [startUpload]);

  return (
    <FilePond
      ref={pondRef}
      files={media}
      onupdatefiles={(fileItems) => setMedia(fileItems.map((fileItem) => fileItem.file))}
      allowMultiple={false}
      allowProcess={false}
      allowRevert={false}
      allowImageCrop={true}
      server={{
        process: (_fieldName, file, _metadata, load, error, progress, abort) => {
          if (signedUrlData) {
            uploadToGCS({
              file,
              url: signedUrlData.url,
              onProgress: () => handleUploadProgress(progress),
              onAbort: () => handleUploadAbort(abort),
              onError: (err: string) => handleUploadError(err, error),
              onLoad: () => handleUploadComplete(load),
            });
          }
        },
      }}
      name="file"
      labelIdle='Drag & Drop Your Media File Here Or <br/><span class="filepond--label-action text-info">Browse</span>'
      instantUpload={false}
      allowFileTypeValidation
      acceptedFileTypes={acceptedFileTypes}
      imageCropAspectRatio={imageAspectRatio}
      imageResizeMode="cover"
    />
  );
};
