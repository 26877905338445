import Cookies, { CookieSetOptions, CookieGetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const getCookieItem = (key: string, options?: CookieGetOptions) => {
  return cookies.get(key, options);
};

export const removeCookieItem = (key: string, options?: CookieSetOptions) => {
  cookies.remove(key, options);
};

export const setCookieItem = (key: string, value: string, options?: CookieSetOptions) => {
  cookies.set(key, value, options);
};

type TokenPair = {
  accessToken: string;
  refreshToken: string;
};
export const setTokenPair = ({ accessToken, refreshToken }: TokenPair) => {
  setCookieItem('accessToken', accessToken, { path: '/', secure: true, sameSite: 'none' });
  setCookieItem('refreshToken', refreshToken, { path: '/', secure: true, sameSite: 'none' });
};

export const getTokenPair = (): TokenPair => {
  return {
    accessToken: getCookieItem('accessToken'),
    refreshToken: getCookieItem('refreshToken'),
  };
};
