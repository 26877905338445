import React from 'react';
import { Media } from '../types';
import ReactPlayer from 'react-player/lazy';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const MediaViewer = ({ mediaObject }: { mediaObject: Media }) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow relative">
        {mediaObject?.type === 'video' ? (
          <ReactPlayer url={mediaObject?.url} controls width="100%" height="100%" className="absolute inset-0" />
        ) : (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[{ uri: mediaObject?.url }]}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
              },
            }}
            className="h-full"
          />
        )}
      </div>
      <h5 className="font-lato font-semibold text-2xl py-3 capitalize">{mediaObject?.title}</h5>
    </div>
  );
};

export default MediaViewer;
