import React, { useState } from 'react';
import { EnrolledCourse, Media } from '../../../types';
import { useEnrolledSections } from './useEnrolledSections';
import SectionAccordion from './SectionAccordion';

const StudentCourseProgressView = ({
  course,
  onMediaSelect,
}: {
  course: EnrolledCourse | undefined;
  onMediaSelect: (media: Media) => void;
}) => {
  const { data: sections } = useEnrolledSections(course?.id || '', course?.sections || []);
  const [activeMediaId, setActiveMediaId] = useState('');

  const handleMediaSelect = (media: Media) => {
    setActiveMediaId(media._id);
    onMediaSelect(media);
  };

  return <SectionAccordion sections={sections || []} onClickMedia={handleMediaSelect} activeMediaId={activeMediaId} />;
};
export default StudentCourseProgressView;
