import { Modal, Alert } from 'flowbite-react';
import React, { useState } from 'react';
import { customModalTheme } from '../custom-themes/customModal';
import { ToastContainer, toast } from 'react-toastify';
import { MediaType } from '../utils/constants';
import { UploadResponse } from '../components/UploadComponent/types';
import { UploadComponent } from './UploadComponent';

type Props = {
  show: boolean;
  loading: boolean;
  imageName: string;
  onSuccess: (cloudObjJson: UploadResponse) => void;
  onHide: () => void;
  imageAspectRatio?: string;
};

const ImageUploader = ({ show, imageName, loading, onSuccess, onHide, imageAspectRatio }: Readonly<Props>) => {
  const [uploadError, setUploadError] = useState('');
  const [startUpload, setStartUpload] = useState(false);

  const handleClose = () => {
    onHide();
  };

  const showErrorToast = () =>
    toast.error('Sorry There Was An Issue Please Try Again Later', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'errorToast',
    });

  const setImageUrlVariable = (cloudObj: UploadResponse) => {
    onSuccess(cloudObj);
  };

  const handleUpload = () => {
    setStartUpload(true);
  };

  const handleUploadError = (err?: string) => {
    if (err) {
      setUploadError(err);
    } else {
      showErrorToast();
    }
    setStartUpload(false);
  };

  return (
    <>
      <Modal show={show} onClose={handleClose} size="md" position="center" theme={customModalTheme}>
        <Modal.Header className="justify-center p-4 border-0">
          <span className="font-bold font-lato">Picture Uploader</span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <UploadComponent
            acceptedFileTypes={['image/*']}
            mediaType={MediaType.IMAGE}
            onError={handleUploadError}
            onSuccess={setImageUrlVariable}
            startUpload={startUpload}
            filename={imageName}
            imageAspectRatio={imageAspectRatio}
          />
        </Modal.Body>
        <Modal.Footer className="pt-1 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={handleClose}
            disabled={loading}
          >
            Close
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins"
            onClick={() => handleUpload()}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer containerId="errorToast" stacked />
    </>
  );
};

export default ImageUploader;
