import { useQuery } from '@tanstack/react-query';
import axios from '../utils/axios';
import { Media } from '../types';

export const useMedia = (ids: string[]) => {
  return useQuery<Media[], Error>({
    queryKey: ['media'],
    queryFn: async () => {
      const promises = ids.map((id) =>
        axios
          .get(`/media/${id}`)
          .then((response) => response.data)
          .catch((error) => ({ error }))
      );

      const results = await Promise.allSettled(promises);
      const data = results
        .filter((result) => result.status === 'fulfilled')
        .map((result: PromiseFulfilledResult<Media>) => result.value);

      return data;
    },
    enabled: ids.length > 0,
  });
};

export const useMediaItem = (id: string) => {
  return useQuery<Media, Error>({
    queryKey: ['media', id],
    queryFn: async () => {
      const response = await axios.get(`/media/${id}`);
      return response.data;
    },
  });
};
