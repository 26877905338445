import { useQuery } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { ProfileData } from '../../../types';

export const useCreator = (id: string) => {
  return useQuery<ProfileData, Error>({
    queryKey: [id],
    queryFn: async () => {
      const res = await axios.get(`/users/${id}`);
      const data = res.data;
      return data;
    },
    enabled: !!id,
  });
};
