import React, { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { CourseEnrollmentData } from '../../../types';
import { currencyFormater } from '../../../utils/utilMethods';
const LecturerCourseEnrollementData = ({ courseId }: { courseId: string | undefined }) => {
  const [courseEnrollmentData, setCourseEnrollmentData] = useState<CourseEnrollmentData>();
  const getCourseEnrollmentData = async () => {
    const res = await axios.get(`/enrollment/stats/${courseId}`);
    setCourseEnrollmentData(res.data);
  };

  useEffect(() => {
    getCourseEnrollmentData();
  }, []);

  return (
    <div className="grid gap-4 lg:grid-cols-2 pt-6">
      <div className="lg:col-span-1">
        <p className="font-lato text-base font-normal text-gray-400 pb-2">Total Number Of Enrollments:</p>
        <p className="font-lato font-extrabold text-3xl">{courseEnrollmentData?.totalEnrolled}</p>
      </div>
      <div className="lg:col-span-1">
        <p className="font-lato text-base font-normal text-gray-400 pb-2">Total Earnings:</p>
        <p className="font-lato font-extrabold text-3xl">{currencyFormater(courseEnrollmentData?.totalAmount)}</p>
      </div>
    </div>
  );
};
export default LecturerCourseEnrollementData;
