import React, { ReactNode } from 'react';
import { Media } from '../../../types';

const MediaItem = ({
  media,
  isActive,
  renderItem,
  onMediaSelect,
}: {
  media: Media;
  isActive?: boolean;
  renderItem?: (title?: string) => ReactNode;
  onMediaSelect?: (media: Media) => void;
}) => {
  if (!media) {
    return <p>Loading...</p>;
  }

  return (
    <div className={`border-b border-b-gray-300 py-4 ${isActive ? 'bg-accent2' : 'hover:bg-gray-200'}`}>
      <div onClick={() => onMediaSelect && onMediaSelect(media)}>
        <div className="ms-6">
          <p className="mb-1 text-sm font-semibold text-gray-700 capitalize font-lato">
            {renderItem ? renderItem(media?.title) : media?.title}
          </p>
          <p className="font-poppins font-medium text-xs text-start ms-3.5">{media?.size}mins</p>
        </div>
      </div>
    </div>
  );
};

export default MediaItem;
