import { EnrolledCourse } from '../../../types';
import { Link } from 'react-router-dom';
import React from 'react';
import { useCreator } from './useCreator';
const StudentCourseComponent = ({ course, key }: { course: EnrolledCourse; key: string }) => {
  const creatorId = course.creator;
  const { data: creator } = useCreator(creatorId || '');
  return (
    <div key={`course-${key}-column`} className="lg:col-span-4 2xl:col-span-3">
      <Link
        to={{
          pathname: `/student/course/${course.id}/view`,
        }}
        className="text-black text-decoration-none"
      >
        <div className="bg-white rounded shadow-sm w-full hover:shadow-primary10 hover:shadow-md">
          <img src={course.thumbnailUrl} alt="" className="p-3 h-52 rounded-lg w-full" />

          <div className="px-3 mb-0">
            <p className="font-lato font-bold text-base mb-0.5 capitalize truncate">{course.title}</p>
            <p className="font-lato font-medium text-sm capitalize text-slate-500">
              {creator?.firstName} {creator?.lastName}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default StudentCourseComponent;
