import React from 'react';
import { EnrolledSection, Media } from '../../../types';
import { Accordion } from 'flowbite-react';
import MediaItem from './MediaItem';

interface SectionAccordionProps {
  activeMediaId?: string;
  onClickMedia?: (media: Media) => void;
  sections: EnrolledSection[];
}

const SectionAccordion = ({ activeMediaId, onClickMedia, sections }: SectionAccordionProps) => {
  if (sections.length <= 0) {
    return <></>;
  }

  return (
    <Accordion>
      {sections.map((section: EnrolledSection) => (
        <Accordion.Panel key={section._id}>
          <Accordion.Title>
            <div>
              <p className="mb-0 text-base font-semibold capitalize font-lato">{section.title}</p>
            </div>
          </Accordion.Title>
          <Accordion.Content className="p-0">
            {section.media.map((sectionMedia, index) => (
              <MediaItem
                key={sectionMedia.mediaId._id}
                renderItem={(text) => `${index + 1}. ${text}`}
                onMediaSelect={onClickMedia}
                isActive={sectionMedia.mediaId._id === activeMediaId}
                media={sectionMedia.mediaId}
              />
            ))}
          </Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default SectionAccordion;
