import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axios';
import { object, ref, string, ValidationError, boolean, array } from 'yup';
import { Alert } from 'flowbite-react';
import FormCarousel from '../components/FormCarousel';
import { AxiosError } from 'axios';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { imageUrls } from '../utils/constants';

const SignUp = () => {
  const [termsBox, setTermsBox] = useState<boolean>(false);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [toggleCPassword, setToggleCPassword] = useState<boolean>(false);
  const [submissionErrors, setSubmissionErrors] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roles: [''],
  });

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoleChange = (event: any) => {
    const { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      roles: [value],
    }));
  };

  const handleCheckBoxChange = (e: any) => {
    setTermsBox(!termsBox);
  };

  const validationSchema = object({
    firstName: string().required('First Name Is Required'),
    lastName: string().required('Last Name Is Required'),
    email: string().required('Email Is Required').email('Invalid Email Format'),
    password: string()
      .required('Password Is Required')
      .min(8, 'Password Must Contain At Least 8 Characters')
      .matches(/[!@#$%^&(),.?":{}|<>]/, 'Password Must Contain At Least One Special Character')
      .matches(/[0-9]/, 'Password Must Contain At Least One Number')
      .matches(/[A-Z]/, 'Password Must Contain At Least One UpperCase Letter')
      .matches(/[a-z]/, 'Password Must Contain At Least One LowerCase Letter'),
    roles: array().required('At Least One Role Must Be Selected'),
    confirmPassword: string()
      .required('Confirm Password Is Required')
      .oneOf([ref('password')], 'Passwords Must Match'),
    termsBox: boolean().required().oneOf([true], 'Check Box Must Be Checked'),
  });

  const PostUserData = async (event: any) => {
    event.preventDefault();
    setSubmissionErrors('');
    try {
      await validationSchema.validate({ ...state, confirmPassword, termsBox }, { abortEarly: false });
      const res = await axios.post(`/auth/local/signup`, state);
      setIsSubmissionSuccessful(true);
      setState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        roles: [''],
      });
      setConfirmPassword('');
      setTermsBox(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setValidationErrors(formErrors);
      } else if (error instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = error.response ? error.response.data.message : defaultErrorMessage;
        setSubmissionErrors(errorMessage);
      } else {
        setSubmissionErrors(error.message);
      }
    }
  };

  return (
    <div className="grid lg:grid-cols-2 overflow-hidden">
      <div className="hidden lg:block overflow-hidden h-screen">
        <FormCarousel />
      </div>

      <div className="overflow-y-auto px-8">
        <div className="h-screen">
          <div className="flex flex-col items-center 2xl:h-full 2xl:justify-center">
            <img
              src={imageUrls.SilLogo}
              alt="Studtitlive Logo"
              className="mx-auto pb-6 pt-2 lg:pt-0 lg:hidden d-block"
            />
            <h2 className="font-lato font-bold text-4xl pb-2 lg:pt-4 text-center">Create Your Account</h2>
            <h6 className="font-lato text-lg font-medium pb-2 text-center">
              Already Have An Account?&nbsp;
              <Link to="/login" className="no-underline dark-blue-color font-bold">
                Login
              </Link>
            </h6>
            {submissionErrors && (
              <Alert color="failure" className="mb-4">
                <span className="font-medium">{submissionErrors}</span>
              </Alert>
            )}
            {isSubmissionSuccessful && (
              <Alert color="success" className="mb-4">
                <p className="font-medium font-lato">A Validation Link Has Been Sent To Your Email</p>
              </Alert>
            )}
            <form className="w-9/12">
              <div className="grid lg:grid-cols-2 gap-4 pb-4">
                <div>
                  <label htmlFor="firstName" className="block font-lato text-lg font-normal pb-2">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="form-input-fields border border-slate-500"
                    value={state.firstName}
                    onChange={handleChange}
                    required
                  />
                  {validationErrors?.firstName && (
                    <p className="text-red-600 font-lato text-base">{validationErrors.firstName}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="lastName" className="block font-lato text-lg font-normal pb-2">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-input-fields border border-slate-500"
                    value={state.lastName}
                    onChange={handleChange}
                    required
                  />
                  {validationErrors?.lastName && (
                    <p className="text-red-600 font-lato text-base">{validationErrors.lastName}</p>
                  )}
                </div>
              </div>

              <label htmlFor="email" className="block font-lato text-lg font-normal pb-2">
                E-mail
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={state.email}
                onChange={handleChange}
                className="form-input-fields border border-slate-500"
                required
              ></input>
              {validationErrors?.email && <p className="text-red-600 font-lato text-base">{validationErrors.email}</p>}

              {/* Todo: Password Toggle */}
              <label htmlFor="password" className="block font-lato text-lg font-normal pb-2 pt-4">
                Password
              </label>
              <div className="relative">
                <div
                  className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  {togglePassword ? <EyeSlash size={20} color="#8692A6" /> : <Eye size={20} color="#8692A6" />}
                </div>
                <input
                  type={togglePassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  className="form-input-fields border border-slate-500 pr-10"
                  required
                ></input>
                {validationErrors?.password && (
                  <p className="text-red-600 font-lato text-base">{validationErrors.password}</p>
                )}
              </div>

              <label htmlFor="confirmPassword" className="block font-lato text-lg font-normal pb-2 pt-4">
                Confirm Password
              </label>
              <div className="relative">
                <div
                  className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer"
                  onClick={() => setToggleCPassword(!toggleCPassword)}
                >
                  {toggleCPassword ? <EyeSlash size={20} color="#8692A6" /> : <Eye size={20} color="#8692A6" />}
                </div>
                <input
                  type={toggleCPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-input-fields border border-slate-500 pr-10"
                  required
                ></input>
                {validationErrors?.confirmPassword && (
                  <p className="text-red-600 font-lato text-base">{validationErrors.confirmPassword}</p>
                )}
              </div>

              <label htmlFor="roles" className="block font-lato text-lg font-normal pb-2 pt-4">
                User Type
              </label>
              <select
                id="roles"
                name="roles"
                value={state.roles[0]}
                onChange={handleRoleChange}
                className="form-input-fields border border-slate-500"
                required
              >
                <option value="">Select a row</option>
                <option value="STUDENT">Student</option>
                <option value="INSTRUCTOR">Lecturer</option>
              </select>
              {validationErrors?.roles && <p className="text-red-600 font-lato text-base">{validationErrors.roles}</p>}
              <div className="flex content-start pt-4">
                <input
                  type="checkbox"
                  name="termsBox"
                  id="termsBox"
                  onChange={handleCheckBoxChange}
                  className="me-2 mt-2"
                  checked={termsBox}
                />
                <label className="capitalize">I agree with the terms of service & condition and Privacy policy</label>
              </div>
              {validationErrors?.termsBox && (
                <p className="text-red-600 font-lato text-base">{validationErrors.termsBox}</p>
              )}

              <button
                onClick={PostUserData}
                className="custom-primary-btn w-full h-12 rounded font-poppins text-xl font-medium mb-4 mt-4"
              >
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
